import { useMemo, forwardRef, useCallback, useState } from 'react'
import { Box, styled, Typography, IconButton, useMediaQuery } from '@mui/material'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Info as InfoIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Stack from '../common/Stack.common'
import { Product, computeProductPrice, Discount, DiscountType } from '../../models/orders.models'
import { ColumnItem, ItemType } from '../../models/props.models'
import { TermsOfSale } from '../../models/materials.models'
import { useTheme } from '@mui/material/styles/index.js'
import ProductCard from './CardProduct.order'
import List, { ListProps, ListRef } from '../common/List.common'
import ModalDiscount from './ModalDiscount.order'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
const RowsTitle = styled(OneLineTypography)({ fontSize: '.75rem', fontWeight: 700 })
const RowDetailStyled = styled(Stack)({
  borderBottom: 'solid 1px #DFDFDF',
  padding: '14px 0',
  width: '100%',
  marginRight: 'auto',
})
const RowDetail = (props: any) => (
  <RowDetailStyled
    {...props}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={1.5}
  />
)
const RowTextLeft = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))
const RowTextRight = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'right',
})

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({ color: theme.palette.primary.main }))
const TooltipContent = styled(Box)({
  fontSize: '.75rem',
})
const RowDetailNoBorder = styled(Stack)({
  padding: '14px 0',
  width: '100%',
})
const RowTextSeveralLines = styled(Typography)({ fontSize: '0.875rem', fontWeight: 400 })
const ProductTitle = styled(Typography)({
  fontSize: '.875rem',
  fontWeight: 600,
  marginBottom: '4px',
})
const ProductSubtitle = styled(Typography)({
  fontSize: '.6875rem',
  fontWeight: 300,
  letterSpacing: -0.14,
})

export interface OrderListProductProps
  extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates' | 'getValues'> {
  onProductChange?: (product: Product) => void
  canUpdate?: boolean
  finishStep?: boolean
  acceptClientStep?: boolean
  values: Product[]
}

const OrderListProduct = forwardRef<ListRef, OrderListProductProps>((props, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const [modal, setModal] = useState<string>('')
  const { values, finishStep, acceptClientStep, canUpdate, onProductChange, ...listProps } = props

  const getValues = useCallback(
    () => ({ data: values, count: values.length, total: values.length }),
    [values],
  )
  const columns = useMemo<ColumnItem[]>(
    () => [
      (product: Product) => {
        const material = product?.material
        return {
          label: t('orders:attributes.product.label'),
          minWidth: '200px',
          key: 'label',
          type: ItemType.custom,
          custom: !product ? (
            <></>
          ) : (
            <Box display="flex" flexDirection="row">
              <img
                src={material.mainImageFile?.src || material.mainImageFile?.path}
                alt="artcile"
                width="42px"
                height="42px"
                style={{ objectFit: 'cover' }}
              />
              <Box display="flex" flexDirection="column" marginLeft="5px">
                <ProductTitle>{material.name}</ProductTitle>
                <ProductSubtitle>{material.reference}</ProductSubtitle>
              </Box>
            </Box>
          ),
        }
      },
      canUpdate
        ? (((product: Product) => {
            const minQuantity =
              finishStep || acceptClientStep
                ? 0
                : product?.material.minQuantity || product?.material.sellByQuantityOf || 1
            let currentQty = product?.material.currentQty

            return {
              label: t('orders:attributes.product.quantity'),
              type: ItemType.number,
              key: 'quantity',
              minWidth: '150px',
              error: product
                ? product.quantity < minQuantity
                  ? t('orders:components.tableProducts.tooFew')
                  : product.quantity > currentQty
                  ? t('orders:components.tableProducts.tooMuch')
                  : null
                : null,
              props: {
                onClick: (e: any) => {
                  e.stopPropagation()
                },
                step: product ? product.material.sellByQuantityOf : 1,
                min: minQuantity,
                max: product ? currentQty : 1,
                onChange: (q?: number) =>
                  onProductChange?.({
                    ...product,
                    quantity: q ?? 0,
                  }),
                showControl: true,
                endAdornment: (
                  <EndAdornmentLabel>
                    {product ? t(`materials:unitSymbol.${product.material.unit}`) : ''}
                  </EndAdornmentLabel>
                ),
              },
            }
          }) as ColumnItem)
        : (product: Product) => ({
            label: t('orders:attributes.product.quantity'),
            key: 'quantity',
            minWidth: '100px',
            formatValue: (quantity) =>
              quantity && product
                ? `${quantity.toFixed(2)}${t(`materials:unitSymbol.${product.material.unit}`)}`
                : '-',
          }),
      ...(!finishStep
        ? [
            (product: Product) => ({
              label: t('orders:attributes.product.unitWeight'),
              key: 'unitWeight',
              minWidth: '80px',
              formatValue: () => {
                const unitWeight = product.material.unitWeight
                if (!unitWeight) {
                  return '-'
                }
                return +unitWeight < 1000
                  ? t('materials:attributes.weight.kg', { value: unitWeight.toFixed(2) })
                  : t('materials:attributes.weight.tonne', {
                      value: (+unitWeight / 1000).toFixed(2),
                    })
              },
            }),
          ]
        : []),
      ...(canUpdate && finishStep
        ? ([
            (product: Product) => ({
              label: t('materials:attributes.sell.termsOfSale'),
              type: ItemType.select,
              key: 'termsOfSale',
              minWidth: '50px',
              error: product?.termsOfSale === TermsOfSale.notDefined ? t('errors:required') : null,
              props: {
                placeholder: t('global:inputs.selectPlaceholder'),
                items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
                onChange: (termsOfSale?: TermsOfSale) =>
                  onProductChange?.({
                    ...product,
                    termsOfSale,
                  }),
              },
            }),
          ] as ColumnItem[])
        : []),
      ((product: Product) => {
        const termsOfSale = product?.termsOfSale ?? product?.material.termsOfSale
        const unitPrice =
          (product?.termsOfSale === TermsOfSale.sale
            ? product?.price
            : !product?.termsOfSale && product?.material.termsOfSale === TermsOfSale.sale
            ? product?.material.price
            : 0) ?? 0

        const baseElem = {
          label: t('orders:attributes.product.unitPrice'),
          key: 'unitPrice',
          minWidth: '130px',
        }
        if (termsOfSale === TermsOfSale.sale && canUpdate && finishStep) {
          return {
            ...baseElem,
            type: ItemType.number,
            formatValue: () => unitPrice,
            error: unitPrice <= 0 ? t('errors:positive') : undefined,
            props: {
              onClick: (e: any) => {
                e.stopPropagation()
              },
              min: 0,
              onChange: (p?: number) =>
                onProductChange?.({
                  ...product,
                  price: p ?? 0,
                }),
              showControl: true,
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`global:currency.${product.material.currency}`)}
                </EndAdornmentLabel>
              ),
            },
          }
        }

        return {
          ...baseElem,
          formatValue: () => {
            if (termsOfSale !== TermsOfSale.sale) {
              return t(`materials:termsOfSale.${termsOfSale}`)
            }
            return `${unitPrice.toFixed(2)} ${t(`global:currency.${product.material.currency}`)}`
          },
        }
      }) as ColumnItem,
      ...(finishStep
        ? [
            (product: Product) => {
              const termsOfSale = product?.termsOfSale ?? product?.material.termsOfSale

              return {
                label: t('orders:attributes.discount.label'),
                key: 'discount',
                minWidth: '40px',
                type: ItemType.number,
                formatValue: (discount: Discount) => {
                  return discount?.value
                },
                props: {
                  readOnly: true,
                  endAdornment:
                    termsOfSale === TermsOfSale.sale ? (
                      <EndAdornmentLabel>
                        {product?.discount?.type === DiscountType.amount && (
                          <Box display="flex">
                            {t(`global:currency.${product.material.currency}`)}
                            <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                          </Box>
                        )}
                        {product?.discount?.type === DiscountType.percentage && (
                          <Box display="flex">
                            {t('global:format.percent', { value: '' })}
                            <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                          </Box>
                        )}
                        {!product?.discount?.type && <AddIcon fontSize="small" />}
                      </EndAdornmentLabel>
                    ) : (
                      <></>
                    ),
                  onClick: (e: any) => {
                    if (termsOfSale === TermsOfSale.sale) {
                      setModal(product.material._id)
                      e.stopPropagation()
                    }
                  },
                },
              }
            },
          ]
        : []),
      (product: Product) => {
        const baseElement = {
          label: t('orders:attributes.product.totalHT'),
          key: 'totalHT',
          minWidth: '100px',
        }
        const termsOfSale = product?.termsOfSale ?? product?.material.termsOfSale
        const unitPrice =
          (product?.termsOfSale === TermsOfSale.sale
            ? product?.price
            : !product?.termsOfSale && product?.material.termsOfSale === TermsOfSale.sale
            ? product?.material.price
            : 0) ?? 0

        if (product?.discount && termsOfSale === TermsOfSale.sale) {
          return {
            ...baseElement,
            type: ItemType.custom,
            custom: (
              <Box display="flex" flexDirection="column">
                <b>
                  {computeProductPrice(product).toFixed(2)}
                  {t(`global:currency.${product.material.currency}`)}
                </b>
                <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                  {(unitPrice * product.quantity).toFixed(2)}
                  {t(`global:currency.${product.material.currency}`)}
                </span>
              </Box>
            ),
          }
        }

        return {
          ...baseElement,
          formatValue: () => {
            if (termsOfSale !== TermsOfSale.sale) {
              return t(`materials:termsOfSale.${termsOfSale}`)
            }
            return `${computeProductPrice(product)}${t(
              `global:currency.${product.material.currency}`,
            )}`
          },
        }
      },
      ...(!finishStep
        ? [
            (product: Product) => {
              const material = product?.material
              const fromDefault = material?.retrieval?.fromDefault !== false
              const hasRetrieval =
                !!material && (!!material.conditioning || !!material.state || !fromDefault)
              return {
                label: t('orders:attributes.product.retrieval.label'),
                key: 'retrieval',
                type: ItemType.tooltip,
                minWidth: '120px',
                props: {
                  title: hasRetrieval && (
                    <TooltipContent>
                      <RowsTitle>{t('orders:attributes.product.retrieval.title')}</RowsTitle>
                      {!fromDefault && !!material.retrieval?.retrievalModality && (
                        <RowDetail
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1.5}>
                          <RowTextLeft>
                            {t('orders:attributes.product.retrieval.modality')}
                          </RowTextLeft>
                          <RowTextRight>
                            {t(`global:retrievalModality.${material.retrieval.retrievalModality}`)}
                          </RowTextRight>
                        </RowDetail>
                      )}
                      {!!material.state && (
                        <RowDetail
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1.5}>
                          <RowTextLeft>
                            {t('orders:attributes.product.retrieval.state')}
                          </RowTextLeft>
                          <RowTextRight>{t(`materials:state.${material.state}`)}</RowTextRight>
                        </RowDetail>
                      )}
                      {material.conditioning && (
                        <RowDetail
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={1.5}>
                          <RowTextLeft>
                            {t('orders:attributes.product.retrieval.conditioning')}
                          </RowTextLeft>
                          <RowTextSeveralLines>{material.conditioning}</RowTextSeveralLines>
                        </RowDetail>
                      )}
                      {!fromDefault &&
                        !!material.retrieval?.startDate &&
                        !!material.retrieval?.endDate && (
                          <RowDetailNoBorder
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1.5}>
                            <RowTextLeft>
                              {t('orders:attributes.product.retrieval.startDate')}
                            </RowTextLeft>
                            <RowTextRight>
                              {`${t('global:format.date', {
                                date: new Date(material.retrieval.startDate || 'invalid date'),
                              })} - ${t('global:format.date', {
                                date: new Date(material.retrieval.endDate || 'invalid date'),
                              })}`}
                            </RowTextRight>
                          </RowDetailNoBorder>
                        )}
                    </TooltipContent>
                  ),
                  children: <>{hasRetrieval && <StyledInfoIcon />}</>,
                },
              }
            },
          ]
        : []),
      ...(canUpdate
        ? [
            (product: Product) => ({
              label: '',
              key: 'delete',
              type: ItemType.custom,
              custom: (
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    onProductChange?.({
                      ...product,
                      quantity: 0,
                    })
                  }}>
                  <DeleteIcon color="primary" />
                </IconButton>
              ),
            }),
          ]
        : []),
    ],
    [t, canUpdate, onProductChange, finishStep, acceptClientStep],
  )

  const card = useMemo(
    () =>
      downMd
        ? (props: any) => (
            <ProductCard
              {...props}
              finishStep={finishStep}
              acceptClientStep={acceptClientStep}
              canUpdate={canUpdate}
              onProductChange={onProductChange}
              onEditDiscount={(materialId: string) => setModal(materialId)}
            />
          )
        : undefined,
    [downMd, canUpdate, onProductChange, finishStep, acceptClientStep],
  )
  const getCardKey = useCallback((product: Product) => product.material._id, [])
  const openProduct = values.find((product: Product) => modal === product.material._id)
  return (
    <>
      <List
        ref={ref}
        {...listProps}
        getValues={getValues}
        disabledPagination
        disableTableScroll
        getCardKey={getCardKey}
        columns={!downMd ? columns : undefined}
        card={card}
      />

      {modal && openProduct && (
        <ModalDiscount
          discount={openProduct?.discount}
          onSubmit={async (discount: any) => {
            onProductChange?.({
              ...openProduct,
              discount,
            })
          }}
          onSuccess={() => {}}
          onClose={() => setModal('')}
        />
      )}
    </>
  )
})

export default OrderListProduct
