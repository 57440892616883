import { Typography, Box, Button, styled } from '@mui/material'
import { Favorite as FavoriteIcon } from '@mui/icons-material'
import { useObservable } from '@ngneat/react-rxjs'
import { useTranslation } from 'react-i18next'

import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import { Mode, Route } from '../models/commons.models'

import { MaterialType } from '../models/materials.models'
import { sessionQuery, sessionService } from '../store/session'
import PageMaterialList from '../components/page/MaterialList.page'
import { useRef } from 'react'

const Title = styled(Typography)({
  marginBottom: '10px',
})
const Subtitle = styled(Typography)({
  textAlign: 'center',
  marginBottom: '38px',
})

const PublicMaterialList = () => {
  useCheckRoute('Favorite', [Mode.front, Mode.storeFront])
  const [user] = useObservable(sessionQuery.user)
  const useImperials = useRef(sessionService.useImperials())

  const { t } = useTranslation()
  const { goTo } = useRoute()

  if (!user?.favorites?.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="100px">
        <Box mb="10px">
          <FavoriteIcon sx={{ fontSize: '43px' }} />
        </Box>
        <Title variant="h3">{t('sessions:pages.favorites.noFavoritesTitle')}</Title>
        <Subtitle>{t('sessions:pages.favorites.noFavoritesDescription')}</Subtitle>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => goTo({ route: Route.publicMaterials })}>
          {t('sessions:pages.favorites.noFavoriteButton')}
        </Button>
      </Box>
    )
  }

  return (
    <PageMaterialList
      useImperials={useImperials.current}
      isPublic
      isFavoritePage
      type={MaterialType.resource}
    />
  )
}
export default PublicMaterialList
