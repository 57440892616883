import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useObservable } from '@ngneat/react-rxjs'

import {
  CropSquare as CropSquareIcon,
  Polyline as PolylineIcon,
  FilterCenterFocus as FilterCenterFocusIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
} from '@mui/icons-material'

import { Route, Mode } from '../../../models/commons.models'

import usePlan from '../../../hooks/usePlan.hooks'
import useRoute, { RoutePath } from '../../../hooks/useRoute.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'

import Constants from '../../../constants'
import { catalogsQuery, catalogsService } from '../../../store/catalogs'
import { categoriesQuery } from '../../../store/categories'
import { materialsQuery } from '../../../store/materials'

import Modal from '../../../components/layout/Modal.layout'
import LoaderOverlay from '../../../components/layout/LoaderOverlay.layout'
import PlanAside from '../../../components/catalog/plan/Aside.plan'
import RoomComponent from '../../../components/catalog/plan/Room.plan'
import LocatedMaterialComponent from '../../../components/catalog/plan/LocatedMaterial.plan'
import MaterialModalForm from '../../../components/material/ModalForm.material'
import ModalDetails from '../../../components/material/ModalDetails.material'

import { logIfDev } from '../../../utils/commons.utils'
import { CatalogStatus } from '../../../models/catalogs.models'
import { sessionService } from '../../../store/session'
const WorkspaceCatalogPlan = () => {
  useCheckRoute('Plan', [Mode.front, Mode.admin, Mode.app])

  const { catalogId, planId } = useParams()
  const show = useSnackbar()
  const { t } = useTranslation()
  const { goTo } = useRoute()

  const [catalog] = useObservable(catalogsQuery.catalog)
  const [catalogLoading] = useObservable(catalogsQuery.detailLoading)
  const [materials] = useObservable(materialsQuery.materials)
  const [materialsLoading] = useObservable(materialsQuery.loading)
  const [categories] = useObservable(categoriesQuery.list)
  const [categoriesLoading] = useObservable(categoriesQuery.loading)
  const [initialised, setInitialised] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmGoTo, setConfirmGoTo] = useState<RoutePath>()
  const useImperials = useRef(sessionService.useImperials())

  const isAdmin = useMemo(() => Constants.mode === Mode.admin, [])
  const canUpdate = useMemo(
    () => (catalog && catalog.status !== CatalogStatus.pending) || isAdmin,
    [catalog, isAdmin],
  )

  const {
    initedPlanId,
    updatedRooms,
    updatedMaterials,
    View,
    drawnedRooms,
    drawnedLocatedMaterials,
    init,
    canUndo,
    undo,
    canRedo,
    redo,
    center,
    isAddingRoom,
    startAddRoom,
    isAddingRectangleRoom,
    startAddRectangleRoom,
    startAddMaterial,
    currentMaterial,
    asideProps,
    viewProps,
    modalProps,
  } = usePlan(canUpdate, useImperials.current)
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  useEffect(() => {
    const getCatalog = async () => {
      try {
        await catalogsService.initDetail(catalogId as string)
      } catch (err: any) {
        show(err)
        goTo({ route: Route.workspace })
      }
    }
    if (!loading && catalogId && (!catalog || catalog._id !== catalogId)) {
      getCatalog()
    }
  }, [goTo, show, catalogId, catalog, loading])

  useEffect(() => {
    //reload when plan changed
    if (planId !== initedPlanId) {
      setInitialised(false)
    }
  }, [planId, initedPlanId])

  useEffect(() => {
    if (
      !initialised &&
      catalog &&
      materials &&
      catalog.plans &&
      !catalogLoading &&
      !materialsLoading &&
      !categoriesLoading
    ) {
      const plan = catalog.plans.find((plan) => plan._id === planId)
      if (plan) {
        init(plan, materials, catalog)
        setInitialised(true)
      } else {
        show(new Error('Plan not found on catalog'))
        goTo({ route: Route.workspaceCatalogPlans, catalogId: catalog._id as string })
      }
    }
  }, [
    initialised,
    categories,
    categoriesLoading,
    catalogLoading,
    materialsLoading,
    goTo,
    show,
    t,
    init,
    catalog,
    materials,
    planId,
    initedPlanId,
  ])

  const onSave = useCallback(
    async (autoSave: boolean) => {
      if (!catalogId || !catalog || !canUpdate) return

      if (!autoSave) setLoading(true)
      try {
        await catalogsService.updateCatalog(
          catalogId,
          {
            ...catalog,
            plans: catalog.plans?.map((plan) =>
              plan._id === planId ? { ...plan, rooms: updatedRooms } : plan,
            ),
          },
          updatedMaterials,
        )
        if (!autoSave) {
          show(t('catalogs:actions.update.success'))
          // reinit : we loose undo/redo ...
          // but it's need to be done at least mode!== app because id can change (in createMaterial for example)
          if (Constants.mode !== Mode.app) {
            setInitialised(false)
          }
        }
      } catch (error: any) {
        if (!autoSave) {
          show(error)
        } else {
          logIfDev(error)
        }
      }
      if (!autoSave) setLoading(false)
    },
    [catalogId, updatedMaterials, updatedRooms, show, t, planId, catalog, canUpdate],
  )

  const goToHandler = useCallback(
    async (routePath: RoutePath) => {
      if (Constants.mode === Mode.app) {
        // make sure two save are not made at the same time
        savedCallback.current = undefined
        await onSave(true)
        goTo(routePath)
      } else if (canUndo) {
        setConfirmGoTo(routePath)
      } else {
        goTo(routePath)
      }
    },
    [canUndo, goTo, onSave],
  )

  const savedCallback = useRef<() => void>()
  useEffect(() => {
    savedCallback.current = onSave.bind(null, true)
  }, [onSave])
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    let id: any = undefined
    if (Constants.mode === Mode.app) {
      id = setInterval(tick, 5000)
    }
    return () => {
      if (id !== undefined) {
        clearInterval(id)
      }
    }
  }, [])

  if (!catalog || !materials || catalogLoading || materialsLoading || categoriesLoading) {
    return <LoaderOverlay />
  }

  return (
    <>
      {loading && <LoaderOverlay />}
      <Box
        display="flex"
        height="100vh"
        width="100vw"
        overflow="hidden"
        position="relative"
        style={{ userSelect: 'none' }}>
        <PlanAside
          catalogId={catalog._id}
          plans={catalog.plans}
          catalogName={catalog.name}
          planId={planId as string}
          categories={categories}
          currentMaterial={currentMaterial}
          onSave={onSave.bind(null, false)}
          goTo={goToHandler}
          {...asideProps}
        />

        <View {...viewProps}>
          {drawnedRooms.map((room) => (
            <RoomComponent key={room._id} {...room} />
          ))}
          {drawnedLocatedMaterials.map((locatedMaterial) => (
            <LocatedMaterialComponent
              key={locatedMaterial._id}
              angle={-viewProps.viewAngle}
              {...locatedMaterial}
            />
          ))}
        </View>

        <Box
          display="flex"
          bottom="10px"
          flexWrap="wrap"
          left={onlyXs ? '0' : `${Constants.ui.planAsideSize}px`}
          width={onlyXs ? '100vw' : `calc(100vw - ${Constants.ui.planAsideSize}px)`}
          justifyContent="center"
          overflow="hidden"
          position="absolute">
          {canUpdate && (
            <>
              <IconButton disabled={!canUndo} onClick={undo} size="large">
                <UndoIcon />
              </IconButton>
              <IconButton disabled={!canRedo} onClick={redo} size="large">
                <RedoIcon />
              </IconButton>
            </>
          )}
          <IconButton onClick={center} size="large">
            <FilterCenterFocusIcon />
          </IconButton>
          {canUpdate && (
            <>
              <IconButton
                color={isAddingRoom ? 'primary' : undefined}
                onClick={startAddRoom}
                size="large">
                <PolylineIcon />
              </IconButton>
              <IconButton
                color={isAddingRectangleRoom ? 'primary' : undefined}
                onClick={startAddRectangleRoom}
                size="large">
                <CropSquareIcon />
              </IconButton>
              <Button variant="contained" size="small" color="primary" onClick={startAddMaterial}>
                {t(`materials:actions.addresource.label`)}
              </Button>
            </>
          )}
        </Box>
      </Box>

      {modalProps.show && modalProps.show !== 'detail' && (
        <MaterialModalForm {...modalProps} useImperials={useImperials.current} />
      )}
      {modalProps.show && modalProps.show === 'detail' && (
        <ModalDetails
          material={{ ...modalProps.material, catalog }}
          onClose={modalProps.onClose}
          useImperials={useImperials.current}
          isPublic={false}
          isCatalogPage={true}
        />
      )}

      {confirmGoTo && (
        <Modal
          title={t(`catalogs:pages.plansTabs.confirmGoToTitle`)}
          description={t(`catalogs:pages.plansTabs.confirmGoToDetails`)}
          onClose={() => setConfirmGoTo(undefined)}
          onConfirm={async () => goTo(confirmGoTo)}
        />
      )}
    </>
  )
}
export default WorkspaceCatalogPlan
