import { useRef } from 'react'
import PageOrderList from '../../components/page/OrderList.page'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { Mode } from '../../models/commons.models'
import { sessionService } from '../../store/session'

const MyOrdersPage = () => {
  useCheckRoute('MyOrder', [Mode.front, Mode.storeFront])
  const useImperials = useRef(sessionService.useImperials())

  return <PageOrderList isClient useImperials={useImperials.current} />
}
export default MyOrdersPage
