import { Fade, IconButton, Menu, MenuItem } from '@mui/material'
import { Language as LanguageIcon } from '@mui/icons-material'
import React, { useState } from 'react'
import { LangEnum } from '../../constants/langs'
import constants from '../../constants'
import { sessionService } from '../../store/session'
const LayoutLangs: React.FC<{ color?: string }> = ({ color }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onMenuClose = () => setAnchorEl(null)

  if (constants.specificCountry) {
    return <></>
  }

  return (
    <>
      <IconButton
        sx={{ color: color }}
        onClick={(evt: React.MouseEvent<HTMLElement>) => setAnchorEl(evt.currentTarget)}>
        <LanguageIcon />
      </IconButton>
      {Boolean(anchorEl) && (
        <Menu
          open
          anchorEl={anchorEl}
          keepMounted
          onClose={onMenuClose}
          TransitionComponent={Fade}
          style={{ marginTop: '50px' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          {Object.keys(LangEnum).map((lang: string) => (
            <MenuItem
              key={lang}
              onClick={() => {
                onMenuClose()
                // @ts-ignore
                sessionService.updateLang(LangEnum[lang])
              }}>
              {/* @ts-ignore */}
              {LangEnum[lang].toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default LayoutLangs
