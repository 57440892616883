import Constants from '../../constants'
import { updateRequestStatus } from '@ngneat/elf-requests'
import { catalogsStore } from './catalogs.store'
import { materialsService } from '../materials/materials.service'

import {
  CatalogLight,
  Catalog,
  CatalogStatus,
  CatalogsPagination,
  ManageCatalog,
  ManagePlan,
  Plan,
  ApiCatalog,
  ApiPlan,
  NetworkStatus,
  isNeeds,
  ResourcesCatalogTypes,
} from '../../models/catalogs.models'
import {
  MaterialQuantity,
  Material,
  MaterialType,
  computeResourceQuantities,
  getRoomDimension,
} from '../../models/materials.models'
import { FileDetails } from '../../models/files.models'
import { Location, Mode } from '../../models/commons.models'
import { SelectOption } from '../../models/props.models'

import { CatalogsApi } from '../../api/catalogs.api'
import { FilesApi } from '../../api/files.api'

import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import { FileUtils } from '../../utils/files.utils'

import { sessionQuery, sessionService } from '../session'
import { catalogsQuery } from './catalogs.query'
import { materialsQuery } from '../materials'

const loadCatalogImages = async (catalog: Catalog): Promise<void> => {
  const load = async (fileDetails: FileDetails): Promise<FileDetails> => {
    return { ...fileDetails, src: await FileUtils.loadImage(fileDetails) }
  }
  catalog.mainImageFile = await load(catalog.mainImageFile)
  catalog.imageFiles = await Promise.all(
    catalog.imageFiles?.map((fileDetails: FileDetails) => load(fileDetails) || []),
  )
  const importPlan = async (plan: Plan) => ({
    ...plan,
    file: await load(plan.file),
  })
  catalog.plans = await Promise.all(catalog.plans?.map((plan: Plan) => importPlan(plan)) || [])
}
const downloadCatalogFiles = async (catalog: Catalog): Promise<void> => {
  const baseFolder = `raedificare/${catalog._id}/`

  catalog.mainImageFile = await FileUtils.importFile(catalog.mainImageFile, `${baseFolder}main`)
  catalog.imageFiles = await Promise.all(
    catalog.imageFiles.map((fileDetails: FileDetails) =>
      FileUtils.importFile(fileDetails, `${baseFolder}image.${fileDetails._id}`),
    ),
  )
  catalog.diagnosticFiles = await Promise.all(
    catalog.diagnosticFiles.map((fileDetails: FileDetails) =>
      FileUtils.importFile(fileDetails, `${baseFolder}diagnostic.${fileDetails._id}`),
    ),
  )
  catalog.otherFiles = await Promise.all(
    catalog.otherFiles.map((fileDetails: FileDetails) =>
      FileUtils.importFile(fileDetails, `${baseFolder}file.${fileDetails._id}`),
    ),
  )
  const importPlan = async (plan: Plan) => ({
    ...plan,
    file: await FileUtils.importFile(
      plan.file,
      `${baseFolder}${FileUtils.nameToPath(plan.name as string)}.${plan._id}`,
      true,
    ),
  })
  catalog.plans = await Promise.all(catalog.plans.map((plan: Plan) => importPlan(plan)))
}

const catalogToLigh = (catalog: Catalog, removeSrc = false): CatalogLight => {
  return {
    _id: catalog._id,
    currency: catalog.currency,
    type: catalog.type,
    interventionTypes: catalog.interventionTypes,
    buildingType: catalog.buildingType,
    organization: catalog.organization,
    projectOwner: catalog.projectOwner,
    name: catalog.name,
    customProjectOwner: catalog.customProjectOwner,
    status: catalog.status,
    location: catalog.location,
    mainImageFile: removeSrc ? { ...catalog.mainImageFile, src: undefined } : catalog.mainImageFile,
    retrieval: catalog.retrieval,
    networkStatus: catalog.networkStatus,
    acceptedAt: catalog.acceptedAt,
    lastSyncAt: catalog.lastSyncAt,
    localUpdatedAt: catalog.localUpdatedAt,
  }
}
const catlogMaterialsToJson = (
  catalogWithMaterials: CatalogWithMaterials,
): CatalogWithMaterials => {
  return {
    catalog: {
      ...catalogWithMaterials.catalog,
      mainImageFile: {
        ...catalogWithMaterials.catalog.mainImageFile,
        src: undefined,
      },
      imageFiles: catalogWithMaterials.catalog.imageFiles.map((file) => ({
        ...file,
        src: undefined,
      })),
      plans: catalogWithMaterials.catalog.plans.map((plan) => ({
        ...plan,
        file: {
          ...plan.file,
          src: undefined,
        },
      })),
    },
    materials: catalogWithMaterials.materials.map((material) => ({
      ...material,
      mainImageFile: {
        ...material.mainImageFile,
        src: undefined,
      },
      imageFiles: material.imageFiles.map((file) => ({
        ...file,
        src: undefined,
      })),
    })),
  }
}

const updateMaterialsFromCatalog = (materials: Material[], catalog: Catalog): void => {
  const plans = catalog.plans
  let quantities: MaterialQuantity[]

  for (let i = 0; i < materials.length; i++) {
    materials[i].catalog = catalogToLigh(catalog)

    if (materials[i]?.retrieval) {
      materials[i].retrieval = {
        ...materials[i].retrieval,
        location: catalog.retrieval.location,
      }
    }

    if (materials[i].type === MaterialType.resource) {
      if (materials[i]?.retrieval?.fromDefault !== false) {
        materials[i].retrieval = {
          fromDefault: true,
          ...catalog.retrieval,
        }
      }

      quantities = []
      // eslint-disable-next-line
      materials[i]?.quantities.forEach((materialQuantity: MaterialQuantity) => {
        if (materialQuantity.plan?._id) {
          let plan = plans.find((plan: Plan) => plan._id === materialQuantity.plan?._id)
          if (!plan) {
            // plan was deleted
            return
          } else {
            if (plan.name !== materialQuantity.plan.name) {
              // plan was rename
              materialQuantity.plan.name = plan.name
            }

            if (materialQuantity.room?._id) {
              let room = plan.rooms.find((room: any) => room._id === materialQuantity.room?._id)
              if (!room || room.name !== materialQuantity.room.name) {
                materialQuantity.room.name = room?.name || ''
              }

              //recompute quantity
              if (materialQuantity.linkToRoom !== false) {
                materialQuantity.quantity =
                  getRoomDimension(plan.scale, materials[i], room) ?? materialQuantity.quantity
              }
            }
          }
        }
        quantities.push(materialQuantity)
      })

      materials[i] = {
        ...materials[i],
        ...computeResourceQuantities(quantities),
      }
    }
  }
}

type CatalogWithMaterials = {
  catalog: Catalog
  materials: Material[]
}

export class CatalogsService {
  store = catalogsStore

  getPathes = (catalogId?: string, paramUserId?: string): { detail: string; list: string } => {
    let userId = paramUserId ?? sessionQuery.getUserId()
    if (userId) {
      return {
        detail: catalogId ? `${userId}/catalog/${catalogId}` : '',
        list: `${userId}/catalogs`,
      }
    } else {
      throw new Error('not connected')
    }
  }

  getCatalogs = async (filters: CatalogsApi.GetListParams): Promise<CatalogsPagination> => {
    await NetworkUtils.checkConnected()
    return await CatalogsApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  getCatalogOptions = async (filters: CatalogsApi.GetListParams): Promise<SelectOption[]> => {
    try {
      const catalogs = await CatalogsApi.getList({
        ...filters,
        ...(Constants.mode === Mode.admin
          ? { platform: sessionService.getAdminPlatform() }
          : { owned: true }),
      })

      return catalogs.data.map((catalog) => ({
        label: catalog.name,
        value: catalog._id,
        data: catalog,
      }))
    } catch (err: any) {
      logIfDev(err)
      return []
    }
  }

  getCatalog = async (catalogId: string, publicCatalog?: boolean): Promise<Catalog> => {
    await NetworkUtils.checkConnected()
    return await CatalogsApi.getById(catalogId, publicCatalog)
  }

  downloadDiagnostic = async (catalog: Catalog, format: 'csv' | 'pdf' | 'xls'): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await CatalogsApi.generateDiag(catalog._id, format)
    await FileUtils.downloadFile(`diag_${catalog.name}.${format === 'xls' ? 'xlsx' : format}`, data)
  }

  init = async (): Promise<void> => {
    let userId = sessionQuery.getUserId()
    if (!userId) {
      this.store.update((state) => ({
        ...state,
        list: [],
      }))
      return
    }

    this.store.update(updateRequestStatus('init', 'pending'))

    const loadLight = async (cat: CatalogLight) => {
      if (cat.networkStatus !== NetworkStatus.remote) {
        try {
          cat.mainImageFile.src = await FileUtils.loadImage(cat.mainImageFile)
        } catch (err: any) {
          logIfDev(err)
        }
        return cat
      } else {
        cat.mainImageFile.src = cat.mainImageFile.path
      }
      return cat
    }

    try {
      const isConnected = await NetworkUtils.isConnected()
      let remoteCatalogs: CatalogLight[] = []
      let localCatalogs: CatalogLight[] = []

      if (Constants.getIsLocal()) {
        const { list } = this.getPathes()
        try {
          localCatalogs = await FileUtils.readJSON(list)
        } catch (err: any) {
          logIfDev(err)
        }
      }

      if (isConnected) {
        const pagination = await this.getCatalogs({
          types: Constants.mode === Mode.app ? ResourcesCatalogTypes : undefined,
          owned: true,
          disablePaginate: true,
        })
        remoteCatalogs = pagination.data

        if (Constants.getIsLocal()) {
          remoteCatalogs.forEach((remoteCatalog: any) => {
            const localIndex = localCatalogs.findIndex(
              (localCatalog: CatalogLight) => remoteCatalog._id === localCatalog._id,
            )
            remoteCatalog = {
              ...catalogToLigh(remoteCatalog),
              networkStatus: NetworkStatus.remote,
              lastSyncAt: new Date(),
              localUpdatedAt: undefined,
            }
            if (localIndex === -1) {
              localCatalogs.push(remoteCatalog)
            } else if (localCatalogs[localIndex].networkStatus === NetworkStatus.remote) {
              localCatalogs[localIndex] = remoteCatalog
            }
          })

          // remove deleted
          localCatalogs = localCatalogs.filter((localCatalog: CatalogLight) => {
            if (localCatalog.networkStatus !== NetworkStatus.local) {
              if (
                !remoteCatalogs.find(
                  (remoteCatalog: CatalogLight) => remoteCatalog._id === localCatalog._id,
                )
              ) {
                this.deleteLocalCatalog(localCatalog._id)
                return false
              }

              return true
            }
            return true
          })

          const { list } = this.getPathes()
          await FileUtils.writeJSON(list, localCatalogs)

          const catalogs = await Promise.all(localCatalogs.map((cat) => loadLight(cat)))
          this.store.update(
            (state) => ({
              ...state,
              list: catalogs,
            }),
            updateRequestStatus('init', 'success'),
          )
        } else {
          const catalogs = await Promise.all(remoteCatalogs.map((cat) => loadLight(cat)))
          this.store.update(
            (state) => ({
              ...state,
              list: catalogs,
            }),
            updateRequestStatus('init', 'success'),
          )
        }
      } else if (Constants.getIsLocal()) {
        const catalogs = await Promise.all(localCatalogs.map((cat) => loadLight(cat)))
        this.store.update(
          (state) => ({
            ...state,
            list: catalogs,
          }),
          updateRequestStatus('init', 'success'),
        )
      } else {
        throw new Error('NOT_CONNECTED')
      }
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  initDetail = async (catalogId: string, publicCatalog?: boolean): Promise<void> => {
    this.store.update(updateRequestStatus('initDetail', 'pending'))

    try {
      let catalog: Catalog
      let materials: Material[] | undefined = undefined
      if (Constants.getIsLocal() && !publicCatalog) {
        const { detail } = this.getPathes(catalogId)
        const { catalog: localCatalog, materials: localMaterials } = (await FileUtils.readJSON(
          detail,
        )) as CatalogWithMaterials
        catalog = localCatalog
        materials = localMaterials
      } else {
        await NetworkUtils.checkConnected()
        catalog = await CatalogsApi.getById(catalogId, publicCatalog)
      }

      await materialsService.init(
        catalogId,
        isNeeds(catalog.type) ? MaterialType.need : MaterialType.resource,
        publicCatalog,
        materials,
      )

      await loadCatalogImages(catalog)
      this.store.update(
        (state) => ({
          ...state,
          detail: catalog,
          isPublic: !!publicCatalog,
        }),
        updateRequestStatus('initDetail', 'success'),
      )
    } catch (err: any) {
      this.store.update(updateRequestStatus('initDetail', 'error', err))
      throw err
    }
  }

  updateLocatlUpdatedAt = async (localUpdatedAt: Date) => {
    this.store.update((state) => ({
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            localUpdatedAt,
          }
        : state.detail,
    }))
  }

  updateStoredCatalog = async (
    { catalog, materials }: CatalogWithMaterials,
    type: 'update' | 'deleteLocal' | 'delete' | 'create',
    oldCatalogId?: string,
  ): Promise<void> => {
    if (Constants.getIsLocal()) {
      const pathes = this.getPathes(catalog._id)

      // do not save src in json
      await FileUtils.writeJSON(
        pathes.list,
        type === 'delete'
          ? this.store.getValue().list.filter((c) => c._id !== catalog._id)
          : type === 'create'
          ? [catalogToLigh(catalog, true), ...this.store.getValue().list]
          : this.store
              .getValue()
              .list.map((catalogLigt: CatalogLight) =>
                catalogLigt._id === catalog._id || catalogLigt._id === oldCatalogId
                  ? catalogToLigh(catalog, true)
                  : catalogLigt,
              ),
      )
      if (type === 'delete' || type === 'deleteLocal') {
        await FileUtils.deleteJSON(pathes.detail)
        await FileUtils.deleteFilesFolder(`raedificare/${catalog._id}`)
      } else {
        await FileUtils.writeJSON(
          pathes.detail,
          catlogMaterialsToJson({
            catalog,
            materials,
          }),
        )
      }
    }

    if (type !== 'delete') {
      await loadCatalogImages(catalog)
    }

    this.store.update((state) => {
      return {
        ...state,
        list:
          type === 'delete'
            ? state.list.filter((c) => c._id !== catalog._id)
            : type === 'create'
            ? [catalogToLigh(catalog), ...state.list]
            : state.list.map((catalogLigt: CatalogLight) =>
                catalogLigt._id === catalog._id || catalogLigt._id === oldCatalogId
                  ? catalogToLigh(catalog)
                  : catalogLigt,
              ),
        // if catalog._id !== oldCatalogId (export new catalog case), we don't update detail so the detail page do not try to reget the catalog (since cataloId in route params will be != from catalogId)
        // it is updated when we change route (with the new id)
        detail:
          type === 'delete' || type === 'deleteLocal'
            ? undefined
            : oldCatalogId && catalog._id !== oldCatalogId
            ? state.detail
            : catalog,
      }
    })

    await materialsService.updateFormCatalogsService(type === 'update' ? materials : [])
  }

  getBackups = async (catalogId: string): Promise<CatalogLight[]> => {
    try {
      if (!Constants.getIsLocal()) {
        throw new Error('can not use local function')
      }
      console.log(catalogId)
      // get catalog deleted & name = backup.${catalogId}
      return []
    } catch (err) {
      logIfDev(err)
      return []
    }
  }
  saveBackup = async (catalogId: string): Promise<void> => {
    try {
      if (!Constants.getIsLocal()) {
        throw new Error('can not use local function')
      }
      const pathes = this.getPathes(catalogId)
      let { catalog, materials } = (await FileUtils.readJSON(pathes.detail)) as CatalogWithMaterials
      if (
        catalog.networkStatus === NetworkStatus.sync &&
        catalog.localUpdatedAt &&
        catalog.lastSyncAt &&
        catalog.lastSyncAt <= catalog?.localUpdatedAt
      ) {
        // TODO will there be an issue with catalog and materials having same embede id
        const { catalog: serverCatalog } = await this.exportCatalogData({
          catalog: {
            ...catalog,
            _id: 'local',
            name: `backup.${catalogId}:${catalog.name}`,
          },
          materials: materials.map((material) => ({
            ...material,
            _id: 'local',
          })),
        })
        await CatalogsApi.remove(serverCatalog._id)
      }
    } catch (err) {
      logIfDev(err)
    }
  }
  restoreBackup = async (): Promise<void> => {
    try {
      if (!Constants.getIsLocal()) {
        throw new Error('can not use local function')
      }
      // TODO
      // try {
      //   await this.saveBackup(catalogId)
      // } catch (err) {
      //   logIfDev(err)
      // }
      // api call 'restore' => this.import(catalogId)
      // api restore must return catalog with same id
      //  => be carefull with orders since backup data and order data do not have same _id
    } catch (err) {
      logIfDev(err)
    }
  }

  importCatalog = async (catalogId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    if (!Constants.getIsLocal()) {
      throw new Error('can not use local function')
    }
    try {
      await this.saveBackup(catalogId)
    } catch (err) {
      logIfDev(err)
    }
    try {
      await FileUtils.deleteFilesFolder(`raedificare/${catalogId}`)
    } catch (_: any) {}

    let catalog = await this.getCatalog(catalogId)
    try {
      await downloadCatalogFiles(catalog)
      catalog = {
        ...catalog,
        networkStatus: NetworkStatus.sync,
        lastSyncAt: new Date(),
        localUpdatedAt: undefined,
      }

      const materials = await materialsService.importMaterials(catalogId)

      await this.updateStoredCatalog({ catalog, materials }, 'update')
    } catch (err: any) {
      catalog.mainImageFile.src = catalog.mainImageFile.path
      catalog = {
        ...catalog,
        networkStatus: NetworkStatus.remote,
        lastSyncAt: new Date(),
        localUpdatedAt: undefined,
      }
      await this.updateStoredCatalog({ catalog, materials: [] }, 'deleteLocal')
      throw err
    }
  }

  exportCatalogData = async ({
    catalog,
    materials,
  }: CatalogWithMaterials): Promise<CatalogWithMaterials> => {
    const handleFile = async (fileDetail: FileDetails): Promise<string> => {
      if (fileDetail._id.includes('local')) {
        const file = new File([await FileUtils.readFile(fileDetail)], fileDetail.path, {
          type: fileDetail.mimeType,
        })
        const createdFileDetails = (await FilesApi.create(file)) as FileDetails
        return createdFileDetails._id
      } else {
        return fileDetail._id.replace('sync.', '')
      }
    }
    const handlePlan = async (plan: Plan) => {
      return {
        ...plan,
        file: await handleFile(plan.file),
      } as any
    }

    let catalogData: ApiCatalog = {
      ...catalog,
      organization: catalog.organization?._id,
      projectOwner: catalog.projectOwner?._id,
      mainImageFile: await handleFile(catalog.mainImageFile),
      imageFiles: await Promise.all(catalog.imageFiles.map((imageFile) => handleFile(imageFile))),
      diagnosticFiles: await Promise.all(
        catalog.diagnosticFiles.map((diagnosticFile) => handleFile(diagnosticFile)),
      ),
      otherFiles: await Promise.all(catalog.otherFiles.map((otherFile) => handleFile(otherFile))),
      plans: await Promise.all(catalog.plans.map((plan) => handlePlan(plan))),
    }

    let serverData = await CatalogsApi.exportCatalog(
      catalogData,
      await materialsService.beforeExport(materials),
    )
    return serverData
  }

  exportCatalog = async (catalogId: string): Promise<Catalog> => {
    await NetworkUtils.checkConnected()
    if (!Constants.getIsLocal()) {
      throw new Error('can not use local function')
    }
    const pathes = this.getPathes(catalogId)
    let { catalog, materials } = (await FileUtils.readJSON(pathes.detail)) as CatalogWithMaterials
    const serverData = await this.exportCatalogData({ catalog, materials })

    await materialsService.afterExport(
      materials,
      serverData.materials,
      catalog._id,
      serverData.catalog._id,
    )

    const moveFile = async (
      file: FileDetails,
      serverFile: FileDetails,
      additionalFrom = '',
      additionalTo = '',
    ) => {
      await FileUtils.replaceIds(
        file,
        [catalogId, file._id.replace('sync.', ''), additionalFrom],
        [serverData.catalog._id, serverFile._id, additionalTo],
      )
      file._id = `sync.${serverFile._id}`
      return file
    }
    serverData.catalog.mainImageFile = await moveFile(
      catalog.mainImageFile,
      serverData.catalog.mainImageFile,
    )
    for (let i = 0; i < catalog.diagnosticFiles.length; i++) {
      serverData.catalog.diagnosticFiles[i] = await moveFile(
        catalog.diagnosticFiles[i],
        serverData.catalog.diagnosticFiles[i],
      )
    }
    for (let i = 0; i < catalog.otherFiles.length; i++) {
      serverData.catalog.otherFiles[i] = await moveFile(
        catalog.otherFiles[i],
        serverData.catalog.otherFiles[i],
      )
    }
    for (let i = 0; i < catalog.imageFiles.length; i++) {
      serverData.catalog.imageFiles[i] = await moveFile(
        catalog.imageFiles[i],
        serverData.catalog.imageFiles[i],
      )
    }
    for (let i = 0; i < catalog.plans.length; i++) {
      serverData.catalog.plans[i].file = await moveFile(
        catalog.plans[i].file,
        serverData.catalog.plans[i].file,
        catalog.plans[i]._id,
        serverData.catalog.plans[i]._id,
      )
    }

    catalog = {
      ...serverData.catalog,
      networkStatus: NetworkStatus.sync,
      lastSyncAt: new Date(),
      localUpdatedAt: catalog.localUpdatedAt
        ? new Date(catalog.localUpdatedAt)
        : catalog.localUpdatedAt,
    }

    await this.updateStoredCatalog(
      { catalog, materials: serverData.materials },
      'update',
      catalogId,
    )

    if (serverData.catalog._id !== catalog._id) {
      await FileUtils.deleteJSON(pathes.detail)
      await FileUtils.deleteFilesFolder(`raedificare/${catalogId}`)
    }

    return catalog
  }

  deleteLocalCatalog = async (catalogId: string): Promise<void> => {
    if (!Constants.getIsLocal()) {
      throw new Error('can not use local function')
    }
    const pathes = this.getPathes(catalogId)
    let { catalog } = (await FileUtils.readJSON(pathes.detail)) as CatalogWithMaterials

    if (await NetworkUtils.isConnected()) {
      catalog = await this.getCatalog(catalogId)
      catalog.mainImageFile.src = catalog.mainImageFile.path
    }
    catalog = {
      ...catalog,
      networkStatus: NetworkStatus.remote,
      lastSyncAt: new Date(),
      localUpdatedAt: undefined,
    }

    await this.updateStoredCatalog({ catalog, materials: [] }, 'deleteLocal')
  }

  createCatalog = async (createdCatalog: ManageCatalog): Promise<Catalog> => {
    let catalog: Catalog
    let {
      mainImageFileFile,
      imageFilesFile,
      plans,
      diagnosticFilesFile,
      otherFilesFile,
      retrieval,
      ...catalogAttribute
    } = createdCatalog
    if (Constants.getIsLocal()) {
      const catalogId = `local.${Math.random().toString().split('.')[1]}`
      const baseFolder = `raedificare/${catalogId}/`
      const saveLocalPlan = async (plan: Plan | ManagePlan) => {
        let planId = `local.${Math.random().toString().split('.')[1]}`
        return {
          ...plan,
          _id: planId,
          file: await FileUtils.saveLocalFile(
            (plan as ManagePlan).file,
            `${baseFolder}${FileUtils.nameToPath(plan.name as string)}.${planId}`,
            false,
          ),
        }
      }

      if (!retrieval) {
        retrieval = {
          location: createdCatalog.location as Location,
        }
      } else if (!retrieval.location) {
        retrieval.location = createdCatalog.location as Location
      }

      catalog = {
        ...catalogAttribute,
        retrieval,
        currency: catalogAttribute.organization?.currency,
        networkStatus: NetworkStatus.local,
        lastSyncAt: undefined,
        localUpdatedAt: new Date(),
        status: CatalogStatus.draft,
        interventionTypes: createdCatalog.interventionTypes || [],
        mainImageFile: await FileUtils.saveLocalFile(
          mainImageFileFile as File,
          `${baseFolder}main`,
          false,
        ),
        imageFiles: await Promise.all(
          imageFilesFile?.map((file: File) =>
            FileUtils.saveLocalFile(file, `${baseFolder}image`),
          ) || [],
        ),
        diagnosticFiles: await Promise.all(
          diagnosticFilesFile?.map((file: File) =>
            FileUtils.saveLocalFile(file, `${baseFolder}diagnostic`),
          ) || [],
        ),
        otherFiles: await Promise.all(
          otherFilesFile?.map((file: File) => FileUtils.saveLocalFile(file, `${baseFolder}file`)) ||
            [],
        ),
        plans: await Promise.all(plans?.map((plan) => saveLocalPlan(plan)) || []),
        _id: catalogId,
      } as Catalog
    } else {
      await NetworkUtils.checkConnected()
      const loadPlan = async (plan: ManagePlan): Promise<any> => {
        return {
          ...plan,
          file: (await FilesApi.create(plan.file))._id,
        }
      }

      const catalogData: ApiCatalog = {
        ...catalogAttribute,
        retrieval,
        organization: catalogAttribute.organization?._id as string,
        projectOwner: catalogAttribute.projectOwner?._id as string,
        mainImageFile: (await FilesApi.create(mainImageFileFile as File))._id,
        imageFiles: (
          await Promise.all(imageFilesFile?.map((file) => FilesApi.create(file)) || [])
        ).map((file: FileDetails) => file._id),
        diagnosticFiles: (
          await Promise.all(diagnosticFilesFile?.map((file) => FilesApi.create(file)) || [])
        ).map((file: FileDetails) => file._id),
        otherFiles: (
          await Promise.all(otherFilesFile?.map((file) => FilesApi.create(file)) || [])
        ).map((file: FileDetails) => file._id),
        plans: await Promise.all(plans?.map((plan) => loadPlan(plan as ManagePlan)) || []),
      } as ApiCatalog
      catalog = await CatalogsApi.create(catalogData)
    }

    await this.updateStoredCatalog({ catalog, materials: [] }, 'create')

    return catalog
  }

  deleteCatalog = async (catalogId: string): Promise<void> => {
    let removeFromApi = true
    if (Constants.getIsLocal()) {
      const pathes = this.getPathes(catalogId)
      const { catalog } = (await FileUtils.readJSON(pathes.detail)) as CatalogWithMaterials
      removeFromApi = catalog.networkStatus !== NetworkStatus.local
    }

    if (removeFromApi) {
      await NetworkUtils.checkConnected()
      await CatalogsApi.remove(catalogId)
    }

    await this.updateStoredCatalog(
      { catalog: { _id: catalogId } as Catalog, materials: [] },
      'delete',
    )
  }

  updateStateCatalog = async (
    catalogId: string,
    update: { status?: CatalogStatus; visible?: boolean },
  ): Promise<Catalog> => {
    await NetworkUtils.checkConnected()
    let catalog = catalogsQuery.getCatalog() as Catalog
    let materials = materialsQuery.getMaterials() as Material[]

    if (!catalog || !materials) {
      throw new Error('Catalog detail need to be loaded')
    }

    if (
      Constants.getIsLocal() &&
      catalog.networkStatus === NetworkStatus.sync &&
      (!catalog.lastSyncAt ||
        (catalog.localUpdatedAt && catalog.lastSyncAt < catalog.localUpdatedAt))
    ) {
      throw new Error('NEED_SYNC')
    }

    if (update.status === CatalogStatus.accepted) {
      update.visible = true
    }

    await CatalogsApi.update(catalogId, update)
    // make sure we keep local variable (so don't use api result)
    await this.updateStoredCatalog({ catalog: { ...catalog, ...update }, materials }, 'update')
    return { ...catalog, ...update }
  }

  updateCatalog = async (
    catalogId: string,
    updatedCatalog: ManageCatalog,
    updatedMaterials?: Material[],
  ): Promise<Catalog> => {
    delete updatedCatalog.status
    delete updatedCatalog.visible

    let catalog = catalogsQuery.getCatalog() as Catalog
    let materials = materialsQuery.getMaterials() as Material[]

    if (!catalog || !materials) {
      throw new Error('Catalog detail need to be loaded')
    }

    if (updatedCatalog.plans) {
      let deletedPlanIds = catalog.plans
        .filter((plan) => !updatedCatalog.plans?.find((updatePlan) => plan._id === updatePlan._id))
        .map((plan) => plan._id)
      let scaledPlans = updatedCatalog.plans.filter(
        (updatePlan) =>
          updatePlan.scale !== catalog.plans?.find((plan) => plan._id === updatePlan._id)?.scale,
      )

      const errors = []
      for (let i = 0; i < materials.length; i++) {
        const { initialQty, currentQty } = computeResourceQuantities(
          materials[i].quantities
            .filter(
              (materialQuantity: MaterialQuantity) =>
                !deletedPlanIds.find(
                  (deletedPlanId) => materialQuantity?.plan?._id === deletedPlanId,
                ),
            )
            .map((materialQuantity: MaterialQuantity) => {
              const scaledPlan = scaledPlans.find(
                (scaledPlan) => materialQuantity?.plan?._id === scaledPlan._id,
              )
              if (scaledPlan && materialQuantity.room && materialQuantity.linkToRoom !== false) {
                const room = scaledPlan.rooms?.find(
                  (room) => materialQuantity?.room?._id === room._id,
                )
                return {
                  ...materialQuantity,
                  quantity:
                    getRoomDimension(scaledPlan.scale!, materials[i], room) ??
                    materialQuantity.quantity,
                }
              }

              return materialQuantity
            }),
        )
        if (initialQty < 0 || currentQty < 0) {
          errors.push({
            material: { _id: materials[i]._id, name: materials[i].name },
            quantity: -Math.min(initialQty, currentQty),
          })
        }
      }
      if (errors?.length > 0) {
        // simulate axios errors ;
        let error: any = new Error('MISSING_QUANTITIES')
        error.response = {
          data: {
            error: 'MISSING_QUANTITIES',
            message: errors,
          },
        }

        throw error
      }
    }

    const {
      plans: updatedPlans,
      imageFilesFile,
      diagnosticFilesFile,
      otherFilesFile,
      mainImageFileFile,
      ...update
    } = updatedCatalog
    const baseFolder = `raedificare/${catalogId}/`
    const saveFile = async (
      file: File,
      filename: string,
      addIdInName?: boolean,
    ): Promise<FileDetails> => {
      return Constants.getIsLocal()
        ? FileUtils.saveLocalFile(file, filename, addIdInName)
        : FilesApi.create(file)
    }

    let mainImageFile: FileDetails = mainImageFileFile
      ? await saveFile(mainImageFileFile as File, `${baseFolder}main`, false)
      : updatedCatalog.mainImageFile || catalog.mainImageFile

    let imageFiles = (
      await Promise.all(
        imageFilesFile?.map((file: File) => saveFile(file, `${baseFolder}image`)) || [],
      )
    ).concat(updatedCatalog.imageFiles || catalog.imageFiles || [])
    let diagnosticFiles = (
      await Promise.all(
        diagnosticFilesFile?.map((file: File) => saveFile(file, `${baseFolder}diagnostic`)) || [],
      )
    ).concat(updatedCatalog.diagnosticFiles || catalog.diagnosticFiles || [])
    let otherFiles = (
      await Promise.all(
        otherFilesFile?.map((file: File) => saveFile(file, `${baseFolder}file`)) || [],
      )
    ).concat(updatedCatalog.otherFiles || catalog.otherFiles || [])

    const loadPlan = async (plan: Plan | ManagePlan): Promise<Partial<Plan>> => {
      let planId = plan.hasOwnProperty('_id') ? (plan as Plan)._id : undefined
      let isManagePlan = !planId
      if (Constants.getIsLocal() && isManagePlan) {
        planId = `local.${Math.random().toString().split('.')[1]}`
      }

      return {
        ...plan,
        _id: planId,
        file: isManagePlan
          ? await saveFile(
              plan.file as File,
              `${baseFolder}${FileUtils.nameToPath(plan.name as string)}.${planId}`,
              false,
            )
          : (plan.file as FileDetails),
      }
    }
    let plans: Partial<Plan>[] = updatedPlans
      ? await Promise.all(updatedPlans.map((plan: Plan | ManagePlan) => loadPlan(plan)))
      : catalog.plans || []

    if (Constants.getIsLocal()) {
      catalog = {
        ...catalog,
        ...update,
        imageFiles,
        diagnosticFiles,
        otherFiles,
        plans: plans as Plan[],
        mainImageFile,
        localUpdatedAt: new Date(),
        networkStatus: catalog.networkStatus,
        lastSyncAt: catalog.lastSyncAt,
      }

      updateMaterialsFromCatalog(updatedMaterials ?? materials, catalog)
    } else {
      await NetworkUtils.checkConnected()

      let apiCatalog: Partial<ApiCatalog> = {
        ...update,
        organization: update.organization?._id,
        projectOwner: update.projectOwner === null ? null : update.projectOwner?._id,
        mainImageFile: mainImageFile._id,
        imageFiles: imageFiles.map((file: FileDetails) => file._id),
        diagnosticFiles: diagnosticFiles.map((file: FileDetails) => file._id),
        otherFiles: otherFiles.map((file: FileDetails) => file._id),
        plans: plans.map(
          (plan: Partial<Plan>) =>
            ({
              ...plan,
              _id: plan._id,
              file: (plan.file as FileDetails)._id,
            } as ApiPlan),
        ),
      }
      if (updatedMaterials) {
        let serverData = await CatalogsApi.exportCatalog(
          {
            ...catalog,
            ...apiCatalog,
            organization: apiCatalog.organization || catalog.organization?._id,
            projectOwner:
              apiCatalog.projectOwner !== undefined
                ? apiCatalog.projectOwner
                : catalog.projectOwner?._id,
          } as ApiCatalog,
          await materialsService.beforeExport(updatedMaterials),
        )
        // we just use server data so we don't have to call for materials.afterExport neither updateMaterialsFromCatalog
        catalog = serverData.catalog
        updatedMaterials = serverData.materials
      } else {
        catalog = await CatalogsApi.update(catalogId, apiCatalog)
        updateMaterialsFromCatalog(materials, catalog)
      }
    }

    await this.updateStoredCatalog({ catalog, materials: updatedMaterials ?? materials }, 'update')

    return catalog
  }

  migrateLocals = async (update: (catalogWithMaterials: any) => Promise<CatalogWithMaterials>) => {
    try {
      const userFolders = await FileUtils.getUsersDir()
      for (let j = 0; j < userFolders.length; j++) {
        const { list } = this.getPathes(undefined, userFolders[j])

        const catalogs = await FileUtils.readJSON(list)
        for (let i = 0; i < catalogs.length; i++) {
          if (catalogs[i].networkStatus !== NetworkStatus.remote) {
            const { detail } = this.getPathes(catalogs[i]._id, userFolders[j])
            const catalogWithMaterials = await update(await FileUtils.readJSON(detail))

            await FileUtils.writeJSON(detail, catlogMaterialsToJson(catalogWithMaterials))

            catalogs[i] = catalogToLigh(catalogWithMaterials.catalog, true)
          }
        }
        await FileUtils.writeJSON(list, catalogs)
      }
    } catch (err: any) {
      logIfDev(err)
    }
  }
}

export const catalogsService = new CatalogsService()
