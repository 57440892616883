import { Preferences } from '@capacitor/preferences'
import { updateRequestStatus } from '@ngneat/elf-requests'

import {
  AuthenticationResponse,
  LoginInterface,
  SignupInterface,
  SignupInterfaceApi,
  CountryParams,
  ContactUsData,
} from '../../models/sessions.models'
import { User, ApiUser, UserType } from '../../models/users.models'
import { Mode, Currency } from '../../models/commons.models'
import { PlatformReference, getPlatform } from '../../models/platforms.models'
import { CatalogType, isNeeds, isResources, CatalogIcon } from '../../models/catalogs.models'
import {
  MaterialType,
  MaterialState,
  Unit,
  MaterialQuantity,
  DimensionUnit,
} from '../../models/materials.models'
import Cookies from 'js-cookie'

import { sessionStore } from './session.store'
import { sessionQuery } from './session.query'
import { catalogsService } from '../catalogs/catalogs.service'

import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import i18n from '../../utils/i18n.utils'
import { FileUtils } from '../../utils/files.utils'

import { setAuthorization, setLang } from '../../api/base.api'
import { FilesApi } from '../../api/files.api'
import { SessionsApi } from '../../api/sessions.api'

import Constants from '../../constants'

import { LangEnum } from '../../constants/langs'
import { changeLanguage } from '../../utils/i18n.utils'
import { materialModelsService } from '../materialModels'

type ShowStatus = { type: CatalogType; isPublic: boolean } | { type: 'user' }

export class SessionService {
  store = sessionStore

  get storageAdminPlatformKey() {
    return `ADMIN_PLATFORM_${Constants.platform}`
  }

  useImperials(): boolean {
    return !!this.store.getValue().user?.useImperials
  }
  catalogIcon(): CatalogIcon | undefined {
    let platformRef = Constants.mode === Mode.admin ? this.getAdminPlatform() : Constants.platform
    const platform = getPlatform(platformRef)
    return platform?.catalogIcon
  }

  showStatus(params: ShowStatus): boolean {
    let platformRef = Constants.mode === Mode.admin ? this.getAdminPlatform() : Constants.platform
    const platform = getPlatform(platformRef)
    if (params.type === 'user') {
      return !platform.autoValidations[params.type]
    }
    const type = isNeeds(params.type) ? 'needs' : 'resources'
    return !params.isPublic && !platform.autoValidations[type]
  }

  showPublicNeeds() {
    return this.showPublic(CatalogType.construction)
  }
  showPublic(type: MaterialType | CatalogType) {
    let platformRef = Constants.mode === Mode.admin ? this.getAdminPlatform() : Constants.platform
    const platform = getPlatform(platformRef)
    return (
      isResources(type as CatalogType) || type === MaterialType.resource || platform.publicNeeds
    )
  }

  get storageAuthKey() {
    return `AUTH_${Constants.platform}`
  }
  get storageCountryKey() {
    return `COUNTRY_${Constants.platform}`
  }

  async getCountry(countryCode: string) {
    return await SessionsApi.getCountry(countryCode)
  }

  getCountryParam(key: keyof CountryParams) {
    return this.store.getValue().countryParams[key]
  }

  async checkAppVersion() {
    if (Constants.mode === Mode.app) {
      let logout = false
      let savedVersion
      try {
        ;({ savedVersion } = await FileUtils.readJSON('config'))
      } catch (err) {}

      try {
        // TRY MIGRATION OF LOCAL DATA
        if (!savedVersion) {
          // update when before adding version handle : 23-10-27
          logIfDev('update to 1')
          savedVersion = 1
        }
        if (savedVersion <= 1.01) {
          // update when TM went to SAAS : ~ 23-12-01
          logIfDev('update to 1.02')
          await catalogsService.migrateLocals(async ({ catalog, materials }: any) => {
            if (
              catalog?.interventionTypes?.find(
                (interventionType: string) =>
                  interventionType === 'resourceCenter' || interventionType === 'storaryArea',
              )
            ) {
              delete catalog?.interventionTypes
              catalog.type = 'storage'
            } else {
              catalog.interventionTypes = catalog?.interventionTypes?.map(
                (interventionType: string) =>
                  interventionType === 'layout' ? 'layoutVRD' : interventionType,
              )
            }

            materials = materials?.map((material: any) => ({
              ...material,
              state:
                material.state === MaterialState.awaitingDeposit ||
                material.state === MaterialState.deposited
                  ? material.state
                  : undefined,
              quantities: material.quantities.map((quantity: any) => ({
                ...quantity,
                type: quantity.order ? 'order' : quantity.position ? 'inventory' : 'others',
              })),
            }))

            return { catalog, materials }
          })
          savedVersion = 1.02
        }

        if (savedVersion <= 1.02) {
          // update when internationalization : ~ 23-12-01
          logIfDev('update to 1.03')
          await catalogsService.migrateLocals(async ({ catalog, materials }: any) => {
            catalog.currency = Currency.EUR
            if (catalog.location) {
              catalog.location.countryCode = 'FR'
            }
            if (catalog.retrieval?.location) {
              catalog.retrieval.location.countryCode = 'FR'
            }

            materials = materials?.map((material: any) => ({
              ...material,
              currency: Currency.EUR,
            }))

            return { catalog, materials }
          })
          savedVersion = 1.03

          // logout to make sure we get lang/currency/countryCode of the user (or user organization)
          logout = true
        }

        if (savedVersion <= 1.03) {
          // update when update imperials unit : ~ 24-06-20
          logIfDev('update to 1.04')
          await catalogsService.migrateLocals(async ({ catalog, materials }: any) => {
            materials = materials?.map((material: any) => {
              if (material.unit === 'lft' || material.unit === 'lft' || material.unit === 'lft') {
                const factor =
                  material.unit === 'lft'
                    ? 3.28084
                    : material.unit === 'ft2'
                    ? 3.28084 * 3.28084
                    : material.unit === 'ft3'
                    ? 3.28084 * 3.28084 * 3.28084
                    : 1
                material = {
                  ...material,
                  unit:
                    material.unit === 'lft'
                      ? Unit.linearMeters
                      : material.unit === 'ft2'
                      ? Unit.squareMeters
                      : material.unit === 'ft3'
                      ? Unit.cubicMeters
                      : material.unit,
                  quantities: material.quantities.map((q: MaterialQuantity) => ({
                    ...q,
                    quantity: q.quantity / factor,
                  })),
                  initialQty: material.initialQty
                    ? material.initialQty / factor
                    : material.initialQty,
                  currentQty: material.currentQty
                    ? material.currentQty / factor
                    : material.currentQty,
                  reservedQty: material.reservedQty
                    ? material.reservedQty / factor
                    : material.reservedQty,
                  sellByQuantityOf: material.sellByQuantityOf
                    ? material.sellByQuantityOf / factor
                    : material.sellByQuantityOf,
                  minQuantity: material.minQuantity
                    ? material.minQuantity / factor
                    : material.minQuantity,
                  unitWeight: material.unitWeight
                    ? material.unitWeight * factor
                    : material.unitWeight,
                  price: material.price ? material.price * factor : material.price,
                }
              }
              if (
                material.dimensions.unit === 'in' ||
                material.dimensions.unit === 'ft' ||
                material.dimensions.unit === 'yd'
              ) {
                const factor =
                  material.dimensions.unit === 'in'
                    ? 25.4
                    : material.dimensions.unit === 'ft'
                    ? 30.48
                    : material.dimensions.unit === 'yd'
                    ? 0.9144
                    : 1

                material.dimensions = {
                  unit:
                    material.dimensions.unit === 'in'
                      ? DimensionUnit.millimeters
                      : material.dimensions.unit === 'ft'
                      ? DimensionUnit.centimeters
                      : material.dimensions.unit === 'yd'
                      ? DimensionUnit.meters
                      : material.dimensions.unit,
                  length: material.dimensions.length
                    ? material.dimensions.length * factor
                    : material.dimensions.length,
                  width: material.dimensions.width
                    ? material.dimensions.width * factor
                    : material.dimensions.width,
                  height: material.dimensions.height
                    ? material.dimensions.height * factor
                    : material.dimensions.height,
                  diameter: material.dimensions.diameter
                    ? material.dimensions.diameter * factor
                    : material.dimensions.diameter,
                }
              }

              return material
            })

            return { catalog, materials }
          })
          savedVersion = 1.03
        }

        // current version 1.04
        // if (savedVersion <= 1.04) {
        // }
      } catch (err) {
        logIfDev(err)
      }
      try {
        await FileUtils.writeJSON('config', { savedVersion: Constants.appVersion })
      } catch (err) {}

      if (logout) {
        this.logout()
      }
    }
  }

  getAdminPlatform(): PlatformReference {
    return this.store.getValue().adminPlatform
  }
  async setAdminPlatform(platform: PlatformReference) {
    if (platform !== this.getAdminPlatform()) {
      await this.storePlatform(platform)
      window.location.reload()
    }
  }

  getFilter(key: string): Record<string, any> {
    return this.store.getValue().filters[key] ?? {}
  }
  setFilter(key: string, value: Record<string, any>) {
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        [key]: value,
      },
    }))
  }

  storePlatform = async (platform: PlatformReference) => {
    try {
      await Preferences.set({
        key: this.storageAdminPlatformKey,
        value: platform + '',
      })
    } catch (err: any) {
      logIfDev(err)
    }
  }
  storeAuthentication = async (data: AuthenticationResponse) => {
    try {
      setAuthorization(data.accessToken)
      if (i18n.language !== data.user.lang) {
        changeLanguage(data.user.lang)
      } else {
        setLang(data.user.lang)
      }

      if (Constants.getIsLocal()) {
        await Preferences.set({
          key: this.storageAuthKey,
          value: JSON.stringify(data),
        })
      } else {
        // cross sub domain auth must use cookies
        Cookies.set(this.storageAuthKey, data.accessToken, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
        })
      }
    } catch (err: any) {
      logIfDev(err)
    }
  }
  storeCountryParams = async (countryParams: CountryParams) => {
    try {
      await Preferences.set({
        key: this.storageCountryKey,
        value: JSON.stringify(countryParams),
      })
    } catch (err: any) {
      logIfDev(err)
    }
  }
  getCountryParams = async (user?: User): Promise<CountryParams | undefined> => {
    const isConnected = await NetworkUtils.isConnected()
    let countryParams: CountryParams | undefined

    if (isConnected) {
      countryParams = await SessionsApi.getCurrentCountry(user)
    } else {
      try {
        const storedCountryParams = await Preferences.get({ key: this.storageCountryKey })
        countryParams = JSON.parse(storedCountryParams.value || '{}')
      } catch (err: any) {
        logIfDev(err)
      }
    }
    if (countryParams) {
      await this.storeCountryParams(countryParams)
      if (!Constants.specificCountry) {
        const getLocFilter = (state: any, route: string, isCatalog = true) => ({
          location: isCatalog
            ? {
                countryCode: countryParams?.countryCode,
                fullAddress: countryParams?.address,
              }
            : {
                location: {
                  countryCode: countryParams?.countryCode,
                  fullAddress: countryParams?.address,
                },
              },
          ...(state.filters[route] ?? {}),
        })
        this.store.update((state) => ({
          ...state,
          filters: {
            ...state.filters,
            ...(this.showPublicNeeds()
              ? {
                  '/public-catalogs/resources': getLocFilter(state, '/public-catalogs'),
                  '/public-materials/resources': getLocFilter(state, '/public-materials', false),
                  '/public-catalogs/needs': getLocFilter(state, '/public-catalogs'),
                  '/public-materials/needs': getLocFilter(state, '/public-materials', false),
                }
              : {
                  '/public-catalogs': getLocFilter(state, '/public-catalogs'),
                  '/public-materials': getLocFilter(state, '/public-materials', false),
                }),
          },
        }))
      }
    }

    return countryParams?.countryCode ? countryParams : undefined
  }

  async init() {
    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      let adminPlatform = Number(
        (await Preferences.get({ key: this.storageAdminPlatformKey }))?.value ?? 1,
      )

      let user: User | undefined = undefined
      let accessToken: string | undefined = undefined
      if (Constants.getIsLocal()) {
        const auth = await Preferences.get({ key: this.storageAuthKey })
        ;({ user, accessToken } = JSON.parse(auth.value || '{}'))
      } else {
        // cross sub domain auth
        accessToken = Cookies.get(this.storageAuthKey)
      }

      if (accessToken) {
        setAuthorization(accessToken)
        const isConnected = await NetworkUtils.isConnected()
        if (isConnected) {
          try {
            user = await SessionsApi.getMe()
            await this.storeAuthentication({
              user,
              accessToken,
            })
          } catch (err: any) {
            accessToken = undefined
            logIfDev(err)
          }
        }
      }

      if (!user || !accessToken) {
        setAuthorization(undefined)
        const countryParams = await this.getCountryParams()
        this.store.update(
          (state) => ({
            ...state,
            countryParams: countryParams ?? state.countryParams,
          }),
          updateRequestStatus('init', 'success'),
        )
        return
      }

      const countryParams = await this.getCountryParams(user)

      const showResourcesStatus = this.showStatus({
        type: CatalogType.deconstruction,
        isPublic: false,
      })
      const showNeedsStatus = this.showStatus({ type: CatalogType.construction, isPublic: false })
      const showUsersStatus = this.showStatus({ type: 'user' })
      const pendginFilters =
        Constants.mode === Mode.admin
          ? [
              ...(showResourcesStatus ? ['/workspace/deconstructions', '/workspace/storages'] : []),
              ...(showNeedsStatus ? ['/workspace/constructions', '/workspace/needs'] : []),
              ...(showUsersStatus ? ['/users'] : []),
            ]
          : []
      this.store.update(
        (state) => ({
          ...state,
          countryParams: countryParams ?? state.countryParams,
          accessToken,
          user,
          adminPlatform: ((user as User).type === UserType.platformAdmin
            ? (user as User).platformOwned
            : !Number.isNaN(adminPlatform)
            ? adminPlatform
            : state.adminPlatform) as PlatformReference,
          filters: pendginFilters.reduce(
            (filter, route: string) => {
              if (!filter[route] || Object.keys(filter[route]).length === 0) {
                filter[route] = { status: ['pending'] }
              }
              return filter
            },
            { ...state.filters },
          ),
        }),
        updateRequestStatus('init', 'success'),
      )
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  logout = async () => {
    await Preferences.remove({ key: this.storageCountryKey })

    if (Constants.getIsLocal()) {
      await Preferences.remove({ key: this.storageAuthKey })
    } else {
      Cookies.remove(this.storageAuthKey, { domain: process.env.REACT_APP_COOKIES_DOMAIN })
    }

    setAuthorization(undefined)
    window.location.href = '/'
  }

  login = async (data: LoginInterface): Promise<AuthenticationResponse> => {
    await NetworkUtils.checkConnected()
    const authenticationResponse = await SessionsApi.login(data)

    if (
      authenticationResponse.user.type !== UserType.admin &&
      authenticationResponse.user.type !== UserType.platformAdmin &&
      Constants.mode === Mode.admin
    ) {
      throw new Error('UNAUTHORIZED')
    }

    const countryParams = await this.getCountryParams(authenticationResponse.user)

    this.store.update((state) => ({
      ...state,
      countryParams: countryParams ?? state.countryParams,
      ...authenticationResponse,
      adminPlatform:
        authenticationResponse.user.type === UserType.platformAdmin &&
        authenticationResponse.user.platformOwned
          ? authenticationResponse.user.platformOwned
          : state.adminPlatform,
    }))
    await this.storeAuthentication(authenticationResponse)
    return authenticationResponse
  }

  updateLang = async (lang: LangEnum) => {
    changeLanguage(lang)
    const isConnected = await NetworkUtils.isConnected()
    if (isConnected && sessionQuery.getUserId()) {
      try {
        await this.updateMyAccount({ lang })
        await materialModelsService.init()
      } catch (err: any) {
        logIfDev(err)
      }
    }
  }

  updateMyAccount = async (data: Partial<ApiUser>): Promise<User> => {
    await NetworkUtils.checkConnected()
    const user = await SessionsApi.updateMe(sessionQuery.getUserId() as string, data)
    this.store.update((state) => ({
      ...state,
      user,
    }))
    return user
  }

  signup = async (data: SignupInterface): Promise<void> => {
    await NetworkUtils.checkConnected()
    let file = !data.organization?._id
      ? await FilesApi.create(data.organization.kbis as File)
      : undefined
    await SessionsApi.signup({
      ...data,
      lang: i18n.language,
      organization: data.organization?._id ? data.organization._id : undefined,
      newOrganization: !data.organization?._id
        ? {
            ...data.organization,
            kbis: file?._id,
          }
        : undefined,
    } as SignupInterfaceApi)
  }

  forgotPassword = async (email: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    await SessionsApi.forgotPassword(
      email,
      Constants.mode === Mode.admin && !!sessionQuery.getUserId()
        ? this.getAdminPlatform()
        : undefined,
    )
  }

  deleteAccount = async (): Promise<void> => {
    await NetworkUtils.checkConnected()
    await SessionsApi.removeMe(sessionQuery.getUserId() as string)
  }

  contactUs = async (contactUsData: ContactUsData): Promise<void> => {
    await NetworkUtils.checkConnected()
    try {
      await SessionsApi.contactUs({
        ...contactUsData,
        translatedSubject: i18n.t(`sessions:subjects.${contactUsData.subject}`),
      })
    } catch (err: any) {
      err.error = 'CONTACT_US'
      throw err
    }
  }
}

export const sessionService = new SessionService()
