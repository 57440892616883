import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import { Mode, Route } from '../../../models/commons.models'
import Tabs from '../../../components/common/Tabs.common'
import { AdminHeaderTitle } from '../../../components/layout/AdminHeader.layout'
import { sessionService } from '../../../store/session'
import { useRef } from 'react'

const MaterialListPage = () => {
  useCheckRoute('MaterialList', [Mode.admin])
  const { t } = useTranslation()
  const { getPath } = useRoute()
  const useImperials = useRef(sessionService.useImperials())

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.materials')} />
      <Tabs
        items={[
          {
            label: t(`global:routes.materialsResources`),
            path: getPath({
              route: Route.materialsResources,
            }),
          },
          {
            label: t(`global:routes.materialsNeeds`),
            path: getPath({
              route: Route.materialsNeeds,
            }),
          },
        ]}
      />
      <Outlet context={{ useImperials: useImperials.current }} />
    </>
  )
}
export default MaterialListPage
