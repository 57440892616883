import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CatalogType, Plan, Catalog, ManageCatalog } from '../../models/catalogs.models'
import { ItemType, DetailItem } from '../../models/props.models'
import { FileDetails } from '../../models/files.models'
import DetailsComponent from '../common/Details.common'
import { Delete as DeleteIcon } from '@mui/icons-material'
import MapComponent from '../common/Map.common'
import Details from '../common/Details.common'
import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import ImageInput from '../common/input/Image.input'
import DocumentsInput from '../common/input/Documents.input'

const Title = styled(Typography)(() => ({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginBottom: '7px',
  paddingTop: '7px',
}))

interface InformationDocumentCatalogProps {
  catalog: Catalog
  canUpdate?: boolean
  isPublic?: boolean
  onUpdate?: (update: ManageCatalog) => void
}
const InformationDocumentCatalog: React.FC<InformationDocumentCatalogProps> = ({
  catalog,
  canUpdate,
  isPublic,
  onUpdate,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const pictures = [catalog.mainImageFile, ...catalog.imageFiles]
  const { t } = useTranslation()

  const theme = useTheme()
  const upLg = useMediaQuery(theme.breakpoints.up('lg'))
  const onlyMd = useMediaQuery(theme.breakpoints.only('md'))

  return (
    <>
      <DetailsComponent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} flexGrow={1}>
            <Title>{t('catalogs:attributes.mainImage')}</Title>
            <ImageInput
              fileDetails={isPublic ? pictures[currentImageIndex] : catalog.mainImageFile}
              readOnly={!canUpdate}
              maxFileSize={2.5}
              height={onlyMd ? '150px' : undefined}
              onChange={(file: File) => onUpdate?.({ mainImageFileFile: file })}
              next={
                isPublic && pictures.length > 1
                  ? () => {
                      setCurrentImageIndex((state) => (state < pictures.length - 1 ? state + 1 : 0))
                    }
                  : undefined
              }
              previous={
                isPublic && pictures.length > 1
                  ? () => {
                      setCurrentImageIndex((state) => (state > 0 ? state - 1 : pictures.length - 1))
                    }
                  : undefined
              }
            />
          </Grid>
          <Grid item sm={12} md={6} flexGrow={1}>
            <Title>{t('catalogs:components.informationDocument.map')}</Title>
            <Box width="100%" position="relative">
              <MapComponent
                height={onlyMd ? '150px' : '300px'}
                disableControls
                hideAutocomplete
                zoom={11}
                center={isPublic ? catalog.retrieval.location.position : catalog.location.position}
                markers={[
                  {
                    id: catalog._id,
                    lat: isPublic
                      ? catalog.retrieval.location.position[0]
                      : catalog.location.position[0],
                    lng: isPublic
                      ? catalog.retrieval.location.position[1]
                      : catalog.location.position[1],
                  },
                ]}
              />
              <Box position="absolute" bottom="-25px">
                <Typography mb="5px" color="primary" textAlign="start">
                  {isPublic ? catalog.retrieval.location.city : catalog.location.city}
                  {isPublic && catalog.retrieval.location.postalCode
                    ? `, ${catalog.retrieval.location.postalCode}`
                    : ''}
                  {!isPublic && catalog.location.postalCode
                    ? `, ${catalog.location.postalCode}`
                    : ''}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DetailsComponent>
      {!isPublic && (
        <Box mt="15px">
          <DetailsComponent title={t('catalogs:components.informationDocument.documents')}>
            <Grid container spacing={2}>
              {catalog.type === CatalogType.construction && (
                <Grid item sm={12} md={6} flexGrow={1}>
                  <DocumentsInput
                    cardSize={upLg ? 110 : 80}
                    label={t('catalogs:attributes.plans.title')}
                    type={'plan'}
                    documents={catalog.plans ?? []}
                    readOnly={!canUpdate}
                    hideValues={true}
                    maxFileSize={2.5}
                    actionIcon={<DeleteIcon />}
                    onSelectFiles={(files: File[]) =>
                      onUpdate?.({
                        plans: [
                          ...files.map((file) => ({ file, name: file.name, scale: 1, angle: 0 })),
                          ...catalog.plans,
                        ],
                      })
                    }
                    onActionDocument={(documentId: string) =>
                      onUpdate?.({
                        plans: catalog.plans.filter((plan: Plan) => plan.file._id !== documentId),
                      })
                    }
                  />
                </Grid>
              )}
              {catalog.type === CatalogType.deconstruction && (
                <Grid item sm={12} md={6} flexGrow={1}>
                  <DocumentsInput
                    cardSize={upLg ? 110 : 80}
                    label={t('catalogs:attributes.diagnostics')}
                    type={'file'}
                    documents={catalog.diagnosticFiles ?? []}
                    readOnly={!canUpdate}
                    maxFileSize={2.5}
                    actionIcon={<DeleteIcon />}
                    onSelectFiles={(documents: File[]) =>
                      onUpdate?.({
                        diagnosticFilesFile: documents,
                        diagnosticFiles: catalog.diagnosticFiles,
                      })
                    }
                    onActionDocument={(documentId: string) =>
                      onUpdate?.({
                        diagnosticFiles: catalog.diagnosticFiles.filter(
                          (diagnosticFile: FileDetails) => diagnosticFile._id !== documentId,
                        ),
                      })
                    }
                  />
                </Grid>
              )}
              <Grid item sm={12} md={6} flexGrow={1}>
                <DocumentsInput
                  cardSize={upLg ? 110 : 80}
                  label={t('catalogs:attributes.images')}
                  type={'image'}
                  documents={catalog.imageFiles ?? []}
                  readOnly={!canUpdate}
                  maxFileSize={2.5}
                  onSelectFiles={(documents) =>
                    onUpdate?.({
                      imageFilesFile: documents,
                      imageFiles: catalog.imageFiles,
                    })
                  }
                  actionIcon={<DeleteIcon />}
                  onActionDocument={(documentId: string) =>
                    onUpdate?.({
                      imageFiles: catalog.imageFiles.filter(
                        (imageFile: FileDetails) => imageFile._id !== documentId,
                      ),
                    })
                  }
                />
              </Grid>
              <Grid item sm={12} md={6} flexGrow={1}>
                <DocumentsInput
                  cardSize={upLg ? 110 : 80}
                  label={t('catalogs:attributes.otherFiles')}
                  type={'file'}
                  documents={catalog.otherFiles ?? []}
                  readOnly={!canUpdate}
                  maxFileSize={2.5}
                  onSelectFiles={(documents) =>
                    onUpdate?.({
                      otherFilesFile: documents,
                      otherFiles: catalog.otherFiles,
                    })
                  }
                  actionIcon={<DeleteIcon />}
                  onActionDocument={(documentId: string) =>
                    onUpdate?.({
                      otherFiles: catalog.otherFiles.filter(
                        (otherFile: FileDetails) => otherFile._id !== documentId,
                      ),
                    })
                  }
                />
              </Grid>
            </Grid>
          </DetailsComponent>
        </Box>
      )}
    </>
  )
}
export default InformationDocumentCatalog
