import { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useSnackbar from '../../../hooks/useSnackbar.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import { organizationsService } from '../../../store/organizations'
import { Organization } from '../../../models/organizations.models'
import { Route, Mode } from '../../../models/commons.models'

import LoaderOverlay from '../../../components/layout/LoaderOverlay.layout'
import Tabs from '../../../components/common/Tabs.common'
import { AdminHeaderTitle } from '../../../components/layout/AdminHeader.layout'

const OrganizationDetailsPage = () => {
  useCheckRoute('OrganizationDetails', [Mode.admin])
  const { organizationId } = useParams()
  const show = useSnackbar()
  const { t } = useTranslation()
  const { goTo, getPath } = useRoute()
  const [organization, setOrganization] = useState<Organization>()

  useEffect(() => {
    const getOrganization = async () => {
      try {
        setOrganization(await organizationsService.getOrganizationById(organizationId as string))
      } catch (err: any) {
        show(err)
        goTo({ route: Route.organizations })
      }
    }
    getOrganization()
  }, [organizationId, show, goTo])

  if (!organization) return <LoaderOverlay />

  return (
    <>
      <AdminHeaderTitle title={organization.name} canGoBack />
      <Tabs
        items={[
          {
            label: t(`global:routes.organizationInformations`),
            path: getPath({
              route: Route.organizationInformations,
              organizationId: organizationId as string,
            }),
          },
          {
            label: t(`global:routes.organizationUsers`),
            path: getPath({
              route: Route.organizationUsers,
              organizationId: organizationId as string,
            }),
          },
        ]}
      />
      <Outlet context={{ organization, setOrganization }} />
    </>
  )
}
export default OrganizationDetailsPage
